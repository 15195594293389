var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_c('div',[_c('img',{staticStyle:{"width":"100%","vertical-align":"top"},attrs:{"src":'/images/unicom-bg.jpg',"alt":""}})]),_c('div',{staticClass:"inpage"},[_c('div',{staticStyle:{"height":"60vw"}}),_c('div',{staticClass:"contentbox"},[_c('table',{staticClass:"dttable"},[_vm._m(0),_c('tr',[_vm._m(1),_vm._m(2),_c('td',[_vm._v("新会员卡")]),_c('td',[_c('van-button',{attrs:{"type":"primary","size":"small","color":"#eb5500"},on:{"click":function($event){return _vm.goPage(3)}}},[_vm._v("套餐详情")])],1)]),_c('tr',[_vm._m(3),_vm._m(4),_c('td',[_vm._v("小赢卡")]),_c('td',[_c('van-button',{attrs:{"type":"primary","size":"small","color":"#eb5500"},on:{"click":function($event){return _vm.goPage(6)}}},[_vm._v("套餐详情")])],1)]),_c('tr',[_vm._m(5),_vm._m(6),_c('td',[_vm._v("海王卡")]),_c('td',[_c('van-button',{attrs:{"type":"primary","size":"small","color":"#eb5500"},on:{"click":function($event){return _vm.goPage(7)}}},[_vm._v("套餐详情")])],1)]),_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('span',{staticStyle:{"color":"#fefbc6","font-weight":"bolder"},on:{"click":_vm.goBaohao}},[_vm._v(" 老号码套餐太贵？【保号套餐】帮您解决 ")])])])])]),_c('div',{staticStyle:{"width":"94.5%","margin":"0 auto","padding-top":"20px"}},[_c('img',{staticStyle:{"width":"100%","border-radius":"10px"},attrs:{"src":'./images/unicom-guanfang.jpg'}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"tdheader"},[_vm._v("月租")]),_c('td',{staticClass:"tdheader"},[_vm._v("流量/月")]),_c('td',{staticClass:"tdheader"},[_vm._v("卡片名")]),_c('td',{staticClass:"tdheader"},[_vm._v("套餐详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticStyle:{"color":"#fefbc6","font-weight":"bold","font-size":"25px"}},[_vm._v("29")]),_vm._v("元 "),_c('div',{staticStyle:{"font-size":"8px"}},[_vm._v("首充100")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticStyle:{"color":"#fefbc6","font-weight":"bold","font-size":"25px"}},[_vm._v("219")]),_vm._v("G")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticStyle:{"color":"#fefbc6","font-weight":"bold","font-size":"25px"}},[_vm._v("19")]),_vm._v("元 "),_c('div',{staticStyle:{"font-size":"8px"}},[_vm._v("首充100")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticStyle:{"color":"#fefbc6","font-weight":"bold","font-size":"25px"}},[_vm._v("135")]),_vm._v("G")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticStyle:{"color":"#fefbc6","font-weight":"bold","font-size":"25px"}},[_vm._v("9")]),_vm._v("元 "),_c('div',{staticStyle:{"font-size":"8px"}},[_vm._v("首充100")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticStyle:{"color":"#fefbc6","font-weight":"bold","font-size":"25px"}},[_vm._v("100")]),_vm._v("G")])
}]

export { render, staticRenderFns }