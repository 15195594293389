<template>
	<div class="bg">
		<div>
			<img :src="'/images/unicom-bg.jpg'" style="width: 100%; vertical-align: top;" alt="">
		</div>
		<div class="inpage">

			<div style="height: 60vw;"></div>
			<div class="contentbox">
				<table class="dttable">
					<tr>
						<td class="tdheader">月租</td>
						<td class="tdheader">流量/月</td>
						<td class="tdheader">卡片名</td>
						<td class="tdheader">套餐详情</td>
					</tr>
					<tr>
						<td><span style="color:#fefbc6; font-weight: bold;font-size: 25px;">29</span>元
							<div style="font-size: 8px;">首充100</div>
						</td>
						<td><span style="color:#fefbc6; font-weight: bold;font-size: 25px;">219</span>G</td>
						<td>新会员卡</td>
						<td>
							<van-button type="primary" size="small" color="#eb5500" @click="goPage(3)">套餐详情</van-button>
						</td>
					</tr>
					<tr>
						<td><span style="color:#fefbc6; font-weight: bold; font-size: 25px;">19</span>元
							<div style="font-size: 8px;">首充100</div>
						</td>
						<td><span style="color:#fefbc6; font-weight: bold;font-size: 25px;">135</span>G</td>
						<td>小赢卡</td>
						<td>
							<van-button type="primary" size="small" color="#eb5500" @click="goPage(6)">套餐详情</van-button>
						</td>
					</tr>
					<tr>
						<td><span style="color:#fefbc6; font-weight: bold;font-size: 25px;">9</span>元
							<div style="font-size: 8px;">首充100</div>
						</td>
						<td><span style="color:#fefbc6; font-weight: bold;font-size: 25px;">100</span>G</td>
						<td>海王卡</td>
						<td>
							<van-button type="primary" size="small" color="#eb5500" @click="goPage(7)">套餐详情</van-button>
						</td>
					</tr>
					<tr>
						<td colspan="4">
							<span style="color: #fefbc6; font-weight: bolder;" @click="goBaohao">
								老号码套餐太贵？【保号套餐】帮您解决
							</span>
						</td>
					</tr>

				</table>

				

			</div>

			<div style="width: 94.5%; margin: 0 auto; padding-top: 20px;">
					<img :src="'./images/unicom-guanfang.jpg'" style="width: 100%;border-radius: 10px;" />
				</div>
		</div>
	</div>
</template>

<script>
import { Button } from 'vant';

export default {
	components:{ [Button.name]:Button},
	data() {
		return {
			cardid: 0,
			who: 0,
			images: []

		}
	},
	mounted() {
		document.title = "中国联通-大流量卡在线开卡"

		this.cardid = this.$Request("g")//goodid
		this.who = this.$Request("f")//fromwhere
		

	},
	methods: {
		getTaocan() {
			
		},
		goPage(id){
			this.$router.push("/taocan?g="+id)
		},
		goBaohao(){
			this.$router.push("/baohao")
		}

	}
}
</script>

<style scoped>
.bg {
	height: 100vh;
	overflow: auto;
	background-image: url(/images/unicom-bg.jpg);
	background-size: auto 100%;
	background-position: center;
	position: relative;
	max-width: 600px;
	margin: 0 auto;
}

.bgimg {
	width: 100%;
	vertical-align: middle;
}

.inpage {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 1;
	top: 0;

}

.contentbox {
	width: 86%;
	margin: 0 auto;
}


.dttable,
.dttable tr th,
.dttable tr td {
	border: 1px solid #fefbc6;
	border-collapse: collapse;
	-webkit-print-color-adjust: exact;
	-moz-print-color-adjust: exact;
	color-adjust: exact;
	padding: 10px;
	 text-align: center;
	 color: #fff;
	
}

.dttable {
	width: 100%;
	border-radius: 10px;
}

.tdheader {
	background-color: #eb5500;
	text-align: center;
	color: #fff;

}
</style>